import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Seo from "../components/seo"
import { motion } from "framer-motion";
import { ArrowNarrowLeftIcon } from '@heroicons/react/solid'
import moment from 'moment'
import HoverVideoPlayer from 'react-hover-video-player'
import Layout from '../components/layout'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function BlogPostTemplate({
  data: { authorImage, coverImage },
  pageContext: { nextPost, page, previousPost },
}) {
  return (
    <Layout>
    <article className="bg-gray-100">
			<Seo title={page.title} />
			<div className="relative px-6 sm:px-8 py-16">
        <div className="max-w-3xl mx-auto">
          <motion.h1
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: '0', opacity: 1 }}
            transition={{ ease: 'easeOut', duration: 0.5 }}
            className="text-center text-4xl mb-6 leading-snug">{page.title}</motion.h1>

          <motion.div
            initial={{ y: -25, opacity: 0 }}
            animate={{ y: '0', opacity: 1 }}
            transition={{ ease: 'easeOut', duration: 0.5 }}
            className="flex justify-center items-center">
            <div className="flex-shrink-0">
              <span className="sr-only">{page.author.name}</span>
							<Img
								fluid={authorImage.localFile.childImageSharp.fluid}
								className="h-12 w-12 rounded-full border-2 border-white shadowl"
							/>
              {/* <img className="h-12 w-12 rounded-full border-2 border-white shadow" src={"post.author.image"} alt="" /> */}
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                <a href={"post.author.twitter"} className="hover:underline">
                  {page.author.name}
                </a>
              </p>
              <div className="flex space-x-1 text-sm">
                <time className="text-gray-400" dateTime={"post.datetime"}>{moment(page.date).format("MMMM Do YYYY")}</time>
                <span aria-hidden="true">&middot;</span>
                <span className="text-gray-500">{Math.round(page.content.text / 60)} mins read</span>
              </div>
            </div>
          </motion.div>
        </div>

        <motion.div
          initial={{ y: 40, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: 'easeOut', duration: 0.5 }}
          className="mt-10 text-lg max-w-prose mx-auto">
          <div className={`h-2 w-full rounded-t-md bg-gradient-to-r from-${"post.category.colorClass"}-400 to-${"post.category.colorClass"}-600`}></div>
          <div className="bg-white px-8 md:px-16 pt-10 pb-16 rounded-b-xl shadow-lg max-w-full">

            <div className="flex items-center justify-between mb-3.5">
							<div>
              {page.category && (
                  <Link className={classNames(`text-${page.category.colour}-500 bg-${page.category.colour}-50 hover:bg-${page.category.colour}-100 inline-block text-xs font-medium rounded-full transition-all duration-350 px-2 py-0.5`)} to={`/blog/category/${page.category.slug}`}>
                  {page.category.name}
                  </Link>
              )}
							</div>
              <Link to="/blog" className="group flex items-center text-sm text-gray-400 hover:text-gray-500 transition-colors duration-150"><ArrowNarrowLeftIcon className="h-4 w-4 text-gray-400 transition-colors duration-150 group-hover:text-gray-500 mr-1.5" />Back to Blog</Link>
            </div>
						{/* {coverImage.fileName.endsWith(".mp4")
								? <HoverVideoPlayer videoSrc={coverImage.url} restartOnPaused className="w-full h-full object-cover transform transition duration-400 group-hover:scale-110" />
								// <video src={post.coverImage.url} />
								: <Img
										fluid={post.coverImage.localFile.childImageSharp.fluid}
										className="w-full h-full object-cover transform transition duration-400 group-hover:scale-110"
										fadeIn={false}
									/>
						} */}
						{coverImage && coverImage.fileName.endsWith(".mp4")
							? <video 
								src={coverImage.url}
								controls="controls"
								className="w-full rounded-lg mb-7" />
							: <Img
								fluid={coverImage.localFile.childImageSharp.fluid}
								className="w-full rounded-lg mb-7"
								fadeIn={false}
							/>
						}	
            {/* <img className="w-full rounded-lg mb-7" src={"post.image"} /> */}

						<div className="prose">
							<MDXRenderer>{page.content.markdownNode.childMdx.body}</MDXRenderer>
						</div>
          </div>
        </motion.div>

				{(nextPost || previousPost) && (
            <div className="mt-10 text-lg max-w-prose mx-auto flex justify-center">
                <div className="text-right ml-2 mr-6 pr-6 border-r-2 flex-1">
              {previousPost && (
								<>
                  <h2 className="text-xs tracking-wide uppercase text-gray-500">
                    Previous Post
                  </h2>
                  <div className="text-blue-500 hover:text-blue-600">
                    <Link to={`/blog/${previousPost.slug}`}>
                      {previousPost.title}
                    </Link>
                  </div>
								</>
              )}
                </div>
                <div className="mr-2 flex-1">
								{nextPost && (
									<>
										<h2 className="text-xs tracking-wide uppercase text-gray-500">
											Next Post
										</h2>
										<div className="text-blue-500 hover:text-blue-600">
											<Link to={`/blog/${nextPost.slug}`}>{nextPost.title}</Link>
										</div>
									</>
								)}
                </div>
            </div>
          )}
					
      </div>
      {/* <header className="pt-6 lg:pb-10">
        <div className="space-y-1 text-center">
          <dl className="space-y-10">
            <div>
              <dt className="sr-only">Published on</dt>
              <dd className="text-base leading-6 font-medium text-gray-500">
                <time dateTime={page.date}>{page.date}</time>
              </dd>
            </div>
          </dl>
          <div>
            <h1 className="text-3xl leading-9 font-extrabold text-gray-900 tracking-tight sm:text-4xl sm:leading-10 md:text-5xl md:leading-14">
              {page.title}
            </h1>
          </div>
        </div>
      </header>
      <div
        className="divide-y lg:divide-y-0 divide-gray-200 lg:grid lg:grid-cols-4 lg:col-gap-6 pb-16 lg:pb-20"
        style={{ gridTemplateRows: 'auto 1fr' }}
      >
        <dl className="pt-6 pb-10 lg:pt-11 lg:border-b lg:border-gray-200">
          <dt className="sr-only">Author</dt>
          <dd>
            <ul className="flex justify-center lg:block space-x-8 sm:space-x-12 lg:space-x-0 lg:space-y-8">
              <li className="flex space-x-2">
                <Img
                  fluid={authorImage.localFile.childImageSharp.fluid}
                  className="w-10 h-10 rounded-full"
                  fadeIn={false}
                />
                <dl className="flex-1 text-sm font-medium leading-5">
                  <dt className="sr-only">Name</dt>
                  <dd className="text-gray-900">{page.author.name}</dd>
                  {page.author.title && (
                    <React.Fragment>
                      <dt className="sr-only">Title</dt>
                      <dd className="text-gray-500">{page.author.title}</dd>
                    </React.Fragment>
                  )}
                </dl>
              </li>
            </ul>
          </dd>
        </dl>
        <div className="divide-y divide-gray-200 lg:pb-0 lg:col-span-3 lg:row-span-2">
          {coverImage && (
            <Img
              fluid={coverImage.localFile.childImageSharp.fluid}
              className="mb-8 rounded"
              fadeIn={false}
            />
          )}
          <div className="prose max-w-none pt-10 pb-8">
            <MDXRenderer>{page.content.markdownNode.childMdx.body}</MDXRenderer>
          </div>
        </div>
        <footer className="text-sm font-medium leading-5 divide-y divide-gray-200 lg:col-start-1 lg:row-start-2">
          {(nextPost || previousPost) && (
            <div className="space-y-8 py-8">
              {nextPost && (
                <div>
                  <h2 className="text-xs tracking-wide uppercase text-gray-500">
                    Next Post
                  </h2>
                  <div className="text-purple-500 hover:text-purple-600">
                    <Link to={`/blog/${nextPost.slug}`}>{nextPost.title}</Link>
                  </div>
                </div>
              )}
              {previousPost && (
                <div>
                  <h2 className="text-xs tracking-wide uppercase text-gray-500">
                    Previous Post
                  </h2>
                  <div className="text-purple-500 hover:text-purple-600">
                    <Link to={`/blog/${previousPost.slug}`}>
                      {previousPost.title}
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="pt-8">
            <Link to="/" className="text-purple-500 hover:text-purple-600">
              &larr; Back to the blog
            </Link>
          </div>
        </footer>
      </div> */}
    </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  fragment AssetFields on GraphCMS_Asset {
    id
		url
		fileName
    localFile {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }

  query BlogPostQuery($id: String!) {
    authorImage: graphCmsAsset(
      authorAvatar: {
        elemMatch: { posts: { elemMatch: { id: { in: [$id] } } } }
      }
    ) {
      ...AssetFields
    }
    coverImage: graphCmsAsset(
      coverImagePost: { elemMatch: { id: { eq: $id } } }
    ) {
      ...AssetFields
    }
  }
`

export default BlogPostTemplate